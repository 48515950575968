import React from "react";

import "./cgu.css";

const CGU = () => {
    return (
        <div className="politique__header">
            <h1>Conditions générales d'utilisation</h1>
        </div>
    )
}


export default CGU;