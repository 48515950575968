import React, { useEffect, useState } from "react";
import axios from "axios";
import { URL } from "../../../../middlewares/request";

import './styles.css';
import CenterCard from "../Templates/CenterCard";
import { centersParis, centersRegion, centersCompetence } from "../../../../data/app/centers";
import OrgaCard from "../Templates/OrgaCard";
import { orgasList } from "../../../../data/app/orgas";


const Centres = () => {
    const [centres, setCentres] = useState([]);
    const [load, setLoad] = useState(false)

    const fetchCentres = async () => {
        await axios
            .get(`${URL}centre/get/`)
            .then((response) => {
                setCentres(response.data);
                setLoad(true)
            })
            .catch((error) => {
                console.log(`error`, error);
            });
    };

    useEffect(() => {
        fetchCentres();
    }, [load]);
    return (
        <div id="centres-page">
            {load ?
                <>

                    <div className="page-card__container">
                        {centres.sort((a, b) => a.id - b.id).map((center) => {
                            if (center.id <= 4) {
                                return <CenterCard {...center} key={center.id} />
                            }
                        })}
                    </div>

                    <div className="page-card__container">
                        {centres.sort((a, b) => a.id - b.id).map((center) => {
                            if (center.id === 17 || (center.id > 4 && center.id < 12)) {
                                return <CenterCard {...center} key={center.id} />
                            }
                        })}
                    </div>

                    <div className="page-card__container">
                        {centres.sort((a, b) => a.id - b.id).map((center) => {
                            if (center.id > 12) {
                                return <CenterCard {...center} key={center.id} />
                            }
                        })}
                    </div>
                </>
                : null}

            <p className="page-card-PS">* Cette liste n'est pas exhaustive. N'hésitez pas à nous contacter si vous souhaitez la compléter.</p>
            <div class="orga">
                <h4 class="orga_title">Notre affiliation aux instances</h4>

                <div className="page-card__container">
                    {orgasList.map((orga) =>
                        <OrgaCard {...orga} key={orga.id} />
                    )}
                </div>


            </div>
        </div>
    )
}

export default Centres;