import axios from "axios";
import { useEffect, useState } from "react";
import { URL } from "../../../middlewares/request";
import CentresList from "./CentresList";

import "./centre.css";
// import { selectAdmin, changePage } from "../../../app/Redux-slices/adminSlice";

export default function CentresReference() {
  const [centres, setCentres] = useState([]);
  const [load, setLoad] = useState(false)

  const [add, setAdd] = useState(false)
  const [error, setError] = useState({ display: false, msg: "" })

  /* Informations du centre */
  const [nomCentre, setNomCentre] = useState("");
  const [poste, setPoste] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [etablissement, setEtablissement] = useState("");
  const [adresse, setAdresse] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [mail, setMail] = useState("");
  const [num, setNum] = useState("")

  const fetchCentres = async () => {
    await axios
      .get(`${URL}centre/get/`)
      .then((response) => {
        setCentres(response.data);
        setLoad(true)
      })
      .catch((error) => {
        console.log(`error`, error);
      });
  };

  const handleAdd = () => {
    if (
      nomCentre === null || nomCentre === "" ||
      etablissement === null || etablissement === "" ||
      adresse === null || adresse === "" ||
      codePostal === null || codePostal === ""
    ) {
      setError({ display: true, msg: "Veuillez vérifier que le nom du centre, l'établissement, l'adresse et le code postal soient bien remplies !" })
    } else {
      sendAdd()
    }
  };

  const sendAdd = async () => {
    await axios.post(URL + "centre/get/",
      {
        nom_centre: nomCentre,
        poste_coordinateur: poste,
        nom: nom,
        prenom: prenom,
        etablissement: etablissement,
        adresse: adresse,
        code_postale: codePostal,
        adresse_mail: mail,
        numero: num,
      }
    )
    setAdd(false)
    let currentPath = window.location.pathname;
    setTimeout(() => {
      window.location.replace(currentPath)
    }, 1000);
  }

  useEffect(() => {
    fetchCentres();
  }, [load]);

  return (
    <>
      <h2 style={{ textAlign: "center", marginBottom: "2rem" }}>
        Gestion des centres de référence
      </h2>
      <button className="ajouter__button" onClick={() => { setAdd(true) }}>Ajouter un centre</button>
      {add ?
        <div className="popUp">
          <i className="fa fa-times" style={{ position: "absolute", color: "#61e0e6", right: "5%", fontSize: "5em" }} onClick={() => { setAdd(false) }}></i>
          <div className="popUp__insider">
            <div className="noteb">
              <p>* Pour les champs : établissement, numéro de téléphone, nom et prénom; S'il y a plusieurs valeurs veuillez mettre un "\" entre les valeurs</p>
              <p><em>Exemple pour le champ prénom : Patrick\Sébastien\Jérémy</em></p>
            </div>
            <h6>Nom du centre :</h6>
            <input type="text" value={nomCentre} placeholder="Nom du centre" onChange={(e) => { setNomCentre(e.target.value) }} />

            <h6>Etablissement :</h6>
            <input type="text" value={etablissement} placeholder="Etablissement" onChange={(e) => { setEtablissement(e.target.value) }} />

            <h6>Adresse :</h6>
            <input type="text" value={adresse} placeholder="Adresse" onChange={(e) => { setAdresse(e.target.value) }} />

            <h6>Code Postal - Ville :</h6>
            <input type="text" value={codePostal} placeholder="Code postal et ville" onChange={(e) => { setCodePostal(e.target.value) }} />

            <h6>Adresse mail :</h6>
            <input type="text" value={mail} placeholder="Adresse mail" onChange={(e) => { setMail(e.target.value) }} />

            <h6>Numéro de téléphone :</h6>
            <input type="text" value={num} placeholder="Numéro de téléphone" onChange={(e) => { setNum(e.target.value) }} />

            <h6>Poste occupé :</h6>
            <input type="text" value={poste} placeholder="Poste occupé" onChange={(e) => { setPoste(e.target.value) }} />

            <h6>Nom :</h6>
            <input type="text" value={nom} placeholder="Nom" onChange={(e) => { setNom(e.target.value) }} />

            <h6>Prénom :</h6>
            <input type="text" value={prenom} placeholder="Prénom" onChange={(e) => { setPrenom(e.target.value) }} />

            {error.display ? <p style={{ color: "red" }}>{error.msg}</p> : null}

            <div className="centre__button">
              <button
                className="registre__button"
                onClick={() => {
                  handleAdd();
                }}>
                Valider
              </button>

              <button
                className="supp_button"
                onClick={() => {
                  setAdd(false);
                }}
              > Annuler</button>

            </div>
          </div>
        </div>
        : null}
      <CentresList centres={centres} />
    </>
  );
}
