import './styles.css';

import MemberCard from "../Templates/MemberCard";
import MissionCard from "../Templates/MissionCard";
import { memberList } from "../../../../data/app/members";
import { missionsList } from "../../../../data/app/missions";
import PDF from "./statuts_apmf_2016.pdf";
import Members from "../../../../images/site/membres.jpg";
import { Grid } from '@material-ui/core';


const Nous = () => {
    return (
        <div id="nous-page">
            <h1>Les membres du bureau</h1>
            {<div className="">
                <div className = "">
                    <Grid container justifyContent='center'>
                    {memberList.map((member) => {
                        if(member.roles.includes('president')) {
                            return (
                                <Grid container justifyContent='center' md={4} sm={6} xs={12} >
                                <MemberCard {...member} key={member.id}/>
                                </Grid>
                            )
                        }
                        return null
                    })}
                    </Grid>
                </div>
                </div>}
            <div className = "page-card__container">
                <Grid container justifyContent='center'>
                {memberList.map((member) => {
                if(member.roles.includes('member')) {
                    return (
                        <Grid container justifyContent='center' md={4} sm={6} xs={12} >
                        <MemberCard {...member} key={member.id}/>
                        </Grid>
                    )
                } else {
                    return null
                }
                })}
                </Grid>
            </div>

            <h1>Les 8 missions de l'APMF</h1>
            <div className = "page-card__container">
                {missionsList.map((mission) => 
                    <MissionCard {...mission} key={mission.id}/>
                )}
            </div> 
            <div className="DownloadMembers">
            <img className="MembersImg" src={Members} />
            <p className="footer_link"> Nos statuts : <a href={PDF} download>Télécharger les statuts de l'APMF (pdf)</a></p>
            </div>
        </div>
    )
}

export default Nous;