import './styles.css';

const Informations = () => {
    
    return (
        <div id="informations-page__container">
            <div className="paragraph">
                <div className="context__Paragraph">
                    
                </div>
                <h3> Droits et Démarches, des particuliers... </h3><br></br>
                <h3> La carte de “soins et d’informations“ </h3>
                <p> L’APMF a participé à la réalisation de la carte de “soins et d’informations“ des patients atteints de la maladie de Fabry. </p>
                <br></br>
                <h3> Le protocole national de diagnostic et de soins (PNDS) </h3>
                <div className="context__Paragraph">
                    <p>
                    L’objectif de ce protocole national de diagnostic et de soins (PNDS) est d’expliciter pour les professionnels de santé la prise en charge optimale et le parcours de soins d’un malade atteint de la maladie de Fabry.
                    </p>
                    <p>
                    Il s’agit d’un outil pragmatique auquel le médecin peut se référer pour la prise en charge de cette maladie, notamment au moment d’établir le protocole de soins conjointement avec le médecin-conseil et le patient. Le PNDS ne peut cependant pas envisager tous les cas spécifiques, toutes les comorbidités, toutes les particularités thérapeutiques, les protocoles de soins hospitaliers, etc. Il ne peut pas revendiquer l’exhaustivité des conduites de prise en charge possibles ni se substituer à la responsabilité individuelle du médecin à l’égard de son patient. Ce protocole reflète cependant la structure essentielle de prise en charge d’un patient atteint de la maladie de Fabry.
                    </p>
                </div>
                <br></br>
                <p className="text__Download">Télécharger le document</p>
                <br></br>
                <h3> Le Plan national 2011-2014 </h3>
                <div className="context__Paragraph">
                    <p><b>
                    Le nouveau Plan national maladies rares comporte 47 mesures regroupées en 3 axes :  
                    </b></p>
                </div>
                <div className="context">
                    <ul>
                        <li>
                        Axe A : renforcer la qualité de la prise en charge des patients
                        </li>
                        <li>
                        Axe B : développer la recherche sur les maladies rares
                        </li>
                        <li>
                        Axe C : amplifier la coopération européenne et internationale afin de partager l’expertise, les expériences et les ressources disponibles sur les 7000 maladies rares recensées. (Rédigé le 15/04/11)
                        </li>
                    </ul>
                </div>
                <div className="context__Paragraph">
                    <p><b>
                    Le 2ème Plan national maladies rares dans son intégralité
                    </b></p>
                </div>
                <div className="context">
                    <ul>
                        <li>
                        Télécharger le 2ème Plan national maladies rares dans son intégralité : &nbsp;
                        <span className="text__Download">Dispositif de suivi Plan national maladies rares (pdf)</span>
                        </li>
                        <li>
                        Télécharger le financement du 2ème plan national maladies rares : &nbsp;
                        <span className="text__Download">Financement du 2ème Plan national maladies rares (pdf)</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Informations;