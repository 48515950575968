import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import "./centre.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import ExpandedIcon from "../product/ExpandedIcon";
import axios from "axios";
import { URL } from "../../../middlewares/request";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: "black",
    width: "20%",
  },
  table: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },

  accordion: {
    marginTop: "30px",
    position: "relative",
    border: "1px solid gray",
  },
  p: {
    fontWeight: "bold",
    margin: 0,
    width: "20%",
  },
}));

export default function Centre({ centre }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState({ display: false, msg: "" })

  /* Informations du centre */
  const [nomCentre, setNomCentre] = useState(centre.nom_centre);
  const [poste, setPoste] = useState(centre.poste_coordinateur);
  const [nom, setNom] = useState(centre.nom);
  const [prenom, setPrenom] = useState(centre.prenom);
  const [etablissement, setEtablissement] = useState(centre.etablissement);
  const [adresse, setAdresse] = useState(centre.adresse);
  const [codePostal, setCodePostal] = useState(centre.code_postale);
  const [mail, setMail] = useState(centre.adresse_mail);
  const [num, setNum] = useState(centre.numero)

  const handleEdit = () => {
    if (
      nomCentre === null || nomCentre === "" ||
      etablissement === null || etablissement === "" ||
      adresse === null || adresse === "" ||
      codePostal === null || codePostal === ""
    ) {
      setError({ display: true, msg: "Veuillez vérifier que le nom du centre, l'établissement, l'adresse et le code postal soient bien remplies !" })
    } else {
      sendChanges()
    }
  };

  const sendChanges = async () => {
    await axios.put(URL + "centre/get/" + centre.id + "/",
      {
        nom_centre: nomCentre,
        poste_coordinateur: poste,
        nom: nom,
        prenom: prenom,
        etablissement: etablissement,
        adresse: adresse,
        code_postale: codePostal,
        adresse_mail: mail,
        numero: num,
      }
    )
    setEdit(false)
    let currentPath = window.location.pathname;
    history.replace(`${currentPath}/replace`);
    setTimeout(() => {
      history.replace(currentPath);
    }, 1000);
  }

  const history = useHistory();

  const val = centre.id;

  const deleteData = async () => {
    axios.delete(URL + "centre/get/" + centre.id + '/');
    let currentPath = window.location.pathname;
    history.replace(`${currentPath}/replace`);
    setTimeout(() => {
      history.replace(currentPath);
    }, 1000);
  };

  const date_centre = new Date(centre.date_publication).toLocaleDateString();

  return (
    <Accordion
      className={classes.accordion}
      key={centre.id}
      onChange={(e, expand) => setExpanded(expand)}
    >
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        {!expanded ? (
          <div className={classes.table}>
            <p className={classes.p}>{centre.nom_centre}</p>
            <p className={classes.p}>{centre.adresse}</p>
            <p className={classes.p}>{centre.code_postale}</p>
          </div>
        ) : (
          <div></div>
        )}

        <ExpandedIcon expanded={expanded} setExpanded={setExpanded} />
      </AccordionSummary>
      <AccordionDetails style={{ margin: "unset", width: "100%" }}>
        <div className="centre__container">
          <h5><b>Nom du centre :</b></h5>
          <p>{centre.nom_centre}</p>
          <div className="centre__insider">
            <div className="left">
              <h6><b>Etablissement(s) :</b></h6>
              <p>{ReactHtmlParser(centre.etablissement.replace(/\\/g, '<br/>'))}</p>
              <h6><b>Adresse :</b></h6>
              <p>{centre.adresse}</p>
              <h6><b>Code postal - Ville :</b></h6>
              <p>{centre.code_postale}</p>

              {centre.adresse_mail !== null && centre.adresse_mail != "" ?
                <>
                  <br />
                  <h6><b>Mail :</b></h6>
                  <p>{ReactHtmlParser(centre.adresse_mail.replace(/\\/g, '<br/>'))}</p>
                </>
                : null}

              {centre.numero !== null && centre.numero != "" ?
                <>
                  <br />
                  <h6><b>Numéro :</b></h6>
                  <p>{ReactHtmlParser(centre.numero.replace(/\\/g, '<br/>'))}</p>
                </>
                : null}
            </div>
            <div className="right">
              {centre.poste_coordinateur !== null && centre.poste_coordinateur != "" ?
                <>
                  <br />
                  <h6><b>Poste :</b></h6>
                  <p>{centre.poste_coordinateur}</p>
                </>
                : null}

              {centre.prenom !== null && centre.prenom != "" ?
                <>
                  <br />
                  <h6><b>Prénom :</b></h6>
                  <p>{ReactHtmlParser(centre.prenom.replace(/\\/g, '<br/>'))}</p>
                </>
                : null}

              {centre.nom !== null && centre.nom != "" ?
                <>
                  <br />
                  <h6><b>Nom :</b></h6>
                  <p>{ReactHtmlParser(centre.nom.replace(/\\/g, '<br/>'))}</p>
                </>
                : null}
            </div>
          </div>

          <div className="centre__button">
            <button
              className="registre__button"
              onClick={() => {
                setEdit(true)
              }}
            >
              <i className="fas fa-edit fa-2x"></i>
            </button>
            <button
              className="supp_button"
              onClick={() => {
                deleteData()
              }}
            >
              <i className="far fa-trash-alt fa-2x"></i>
            </button>
          </div>
        </div>
      </AccordionDetails>
      {edit ?
        <div className="popUp">
          <i className="fa fa-times" style={{ position: "absolute", color: "#61e0e6", right: "5%", fontSize: "5em" }} onClick={() => { setEdit(false) }}></i>
          <div className="popUp__insider__centre">
            <div className="noteb">
              <p>* Pour les champs : établissement, numéro de téléphone, nom et prénom; S'il y a plusieurs valeurs veuillez mettre un "\" entre les valeurs</p>
              <p><em>Exemple pour le champ prénom : Patrick\Sébastien\Jérémy</em></p>
            </div>
            <h6>Nom du centre :</h6>
            <input type="text" value={nomCentre} placeholder="Nom du centre" onChange={(e) => { setNomCentre(e.target.value) }} />

            <h6>Etablissement :</h6>
            <input type="text" value={etablissement} placeholder="Etablissement" onChange={(e) => { setEtablissement(e.target.value) }} />

            <h6>Adresse :</h6>
            <input type="text" value={adresse} placeholder="Adresse" onChange={(e) => { setAdresse(e.target.value) }} />

            <h6>Code Postale - Ville :</h6>
            <input type="text" value={codePostal} placeholder="Code postal et ville" onChange={(e) => { setCodePostal(e.target.value) }} />

            <h6>Adresse mail :</h6>
            <input type="text" value={mail} placeholder="Adresse mail" onChange={(e) => { setMail(e.target.value) }} />

            <h6>Numéro de téléphone :</h6>
            <input type="text" value={num} placeholder="Numéro de téléphone" onChange={(e) => { setNum(e.target.value) }} />

            <h6>Poste occupé :</h6>
            <input type="text" value={poste} placeholder="Poste occupé" onChange={(e) => { setPoste(e.target.value) }} />

            <h6>Nom :</h6>
            <input type="text" value={nom} placeholder="Nom" onChange={(e) => { setNom(e.target.value) }} />

            <h6>Prénom :</h6>
            <input type="text" value={prenom} placeholder="Prénom" onChange={(e) => { setPrenom(e.target.value) }} />
            {error.display ? <p style={{ color: "red" }}>{error.msg}</p> : null}

            <div className="centre__button">
              <button
                type="submit"
                className="registre__button"
                onClick={(e) => {
                  e.preventDefault();
                  handleEdit();
                }}>
                Valider
              </button>

              <button
                className="supp_button"
                onClick={() => {
                  setEdit(false);
                }}
              > Annuler</button>

            </div>
          </div>
        </div>
        : null}
    </Accordion>
  );
}
